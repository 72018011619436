body {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
  --hr-color: #ececec;
  --box-shadow-v1: rgba(0, 0, 0, 0.15);
  --box-shadow-v2: rgba(102, 102, 102, 0.5);
  --blue-v1: #0096d6;
  --blue-v2: #0195d6;
  --blue-v3: #0096d5;
  --green: #4fa33d;
  --orange: #f28200;
  --purple: #a33fa3;
  --gray-v1: #666666;
  --gray-v2: #999999;
  --gray-v3: #757574;
  --background: #f2f2f2;
  --bg-v1: #f9f9f9;
  --bg-v2: #7e7676;
  --bg-v3: #e6e6e680;
  --bg-v4: #f8f8f8;
  --bg-v5: #f8e6e6;
  --bg-v6: #EEEEEE;
  --bg-v7: #0084bc;
  --bg-v8: #f3f3f3;
  --bg-v9: #def1f5;
  --bg-v10: #cccccc;
  --white: white;
  --red: red;
  --astrik: #cf4030;
  --tracker: #F28202;
  --underline: #e0e0e0;
  --border: #e6e6e6;
}

body {
  margin: 0;
  padding: 0;
}

h3 {
  margin-bottom: 20px;
}

hr {
  border: 1px solid var(--hr-color, #ececec);
}

.anchor {
  font-weight: normal;
  cursor: pointer;
  user-select: none;
}

@media (max-width: 400px) {
  .filterPopupForMobile .vn-modal__dialog {
    width: 300px;
  }
}

@media (max-width: 420px) {

  .selectCustomer,
  .siteSelection,
  .selectCountry,
  .showCbnCrs,
  .selectContract {
    margin-right: 0px !important;
    max-width: 100% !important;
    width: 100% !important;
  }
}

@media (max-width: 550px) {
  .advanceFilterModal .vn-modal__body .tableRow .tableTd {
    font-size: 14px;
  }

  .filterPopupForMobile .vn-modal__dialog {
    width: 350px;
  }
}

@media (max-width: 600px) {
  .leftAlign td:nth-child(4) span {
    position: relative;
    right: 7px;
  }
  .catalogBundleAlign td:nth-child(8) span {
    position: relative;
  }
  .buttonPosition td:nth-child(3) span {
    position: relative;
    right: 70px;
  }
}

@media (max-width: 767px) {
  .wrapper .vn-search {
    margin-left: 0px;
    width: -webkit-fill-available;
  }

  .headerAlignment {
    width: min-content;
  }

  .leftButton,
  .rightButton {
    width: 50%;
  }

  .contractMoreIcon .vn-contextual-menu {
    position: relative;
    bottom: 105px;
    left: 60px;
  }

  .card .vn-panel__content {
    max-width: 280px !important;
    width: 280px !important;
    min-width: 280px !important;
  }

  .col.estimatedDeliveryBlock {
    padding-right: 0px;
  }

  .col.viewStatusButton {
    text-align: left;
  }

  .row.estimateWrapper {
    margin-bottom: 20px;
  }

  button.width50 {
    width: 50%;
  }
  .textboxButtons .vn-button--secondary {
    margin-left: 10px;
  }

  button.width100 {
    width: 100%;
  }

  .viewStatusButton div a {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .cancelReasonBox {
    margin-top: 3px;
    margin-left: 58px;
  }

  .approveCancellation .vn-button {
    margin-top: 20px;
    margin-left: 60px;
  }

  .approveOrder .vn-button {
    width: 130px;
  }

  .selectWrapper .vn-select {
    position: absolute;
    left: 380px;
    right: 40px;
  }
}

@media (max-width: 780px) {
  .advanceFilterModal:not(.locationSelectorModal) .vn-modal__content {
    padding: 31px 30px 16px 30px;
    margin: 0px;
    height: 100%;
    width: 100%;
  }

  .advanceFilterModal .vn-radio-button-box {
    flex: 0;
    left: -35px;
  }

  .advanceFilterModal:not(.locationSelectorModal) .vn-modal__body {
    background-color: var(--background, #f2f2f2);
    height: calc(100% - 96px);
  }

  .advanceFilterModal:not(.locationSelectorModal) table tbody {
    background-color: var(--background, #f2f2f2);
  }

  .advanceFilterModal .tableTd {
    font-size: 17px;
  }

  .advanceFilterModal table tbody tr td {
    margin-top: 0px;
    padding: 8px;
  }

  .advanceFilterModal .vn-modal__dialog {
    margin-top: 0px;
    height: 100%;
    width: 100%;
  }

  .advanceFilterModal:not(.locationSelectorModal) .vn-modal__header {
    padding-top: 10px;
    padding-left: 25px;
    margin-bottom: 10px;
  }

  .advSearchBtnsComp {
    background-color: var(--white, white);
    z-index: 1;
    height: 65px;
    display: flex;
    width: 100%;
    border-top: 1px solid var(--bg-v6, #EEEEEE);
    padding-top: 15px;
    padding-left: 20px;
    margin: 0px;
    position: fixed;
    bottom: 0px;
  }
}

@media (max-width: 800px) {
  .buttonWrapper .vn-button--secondary {
    top: 20px;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .leftAlign td:nth-child(4) span {
    position: relative;
    right: 22px;
  }

  .catalogBundleAlign td:nth-child(8) span {
    position: relative;
  }

  .buttonPosition td:nth-child(3) span {
    position: relative;
    right: 100px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .displayFlex .pmButton {
    flex: 1;
    padding-right: 14px;
  }

  .dropdown,
  .rightButton {
    width: 50%;
  }

  .leftButton,
  .rightButton {
    width: 50%;
  }

  .wrapper .vn-search {
    margin-top: 10px;
    margin-left: 15px;
    width: 50%;
  }

  .tabletWrapper {
    display: contents;
  }

  .selectContract.dropdown {
    padding-left: 15px;
  }

  .manageContract .vn-button {
    width: 170px;
  }
}

@media (max-width: 1020px) {
  .customerTab .vn-tabs__tab {
    padding: 7px 18px !important;
    margin-right: 29px;
 }

  .orderListTable,
  .contractTable,
  .documentTable {
    padding-top: 0px;
  }

  .orderListTable .tableRow:nth-child(odd) {
    background-color: var(--bg-v4, #f8f8f8);
  }

  .orderListTable .tableRow,
  .contractTable .tableRow,
  .documentTable .tableRow {
    margin-bottom: 0px;
  }

  .orderListTable .row,
  .contractTable .row,
  .documentTable .row {
    background-color: var(--white, white);
    padding: 10px 12px 10px 12px;
  }

  .col.filterCol {
    margin-left: 10px;
  }
}

@media (max-width: 1023px) {
  .spacing .vn-panel__content {
    padding: 6px 0px 6px 0px !important;
    background-color: var(--bg-v3,#e6e6e680);
  }

  .caseRequestDocument .tableRow {
    margin-bottom: 0px;
  }

  .rightButton .vn-button--primary {
    width: -webkit-fill-available;
  }

  .rightBlock {
    width: 100%;
  }

  .contractInformation {
    margin-bottom: 15px;
  }

  .requestContractChange .vn-button {
    font-size: small !important;
  }
}

@media (min-width: 1023px) {
  .wrapper {
    margin-top: 10px;
  }

  .contractInformation {
    width: 100%;
    margin-right: 10px;
    display: flex;
    height: max-content;
  }

  .hpContactsNew {
    width: 50%;
  }

  .hpContacts {
    width: 50%;
    margin-left: 10px;
  }

  .rejectOrderButtons {
    margin-top: 0px;
  }

  .cancelOrderButtons {
    display: flex;
    margin-top: 0px;
  }

  .btnSubmit {
    margin: 0px 15px;
  }

  .caseRequestDocument .tableTd {
    width: 29%;
  }

  .approveOrder .vn-button:first-child {
    margin-left: 57px;
  }

  .textboxButtons {
    display: contents !important;
  }
}

@media (min-width: 1023px) and (max-width: 1200px) {
  .pageSection {
    padding: 20px 40px 10px 0px;
  }
}

@media (min-width: 1200px) {
  .vn-appbar__menu--enabled {
    padding-left: 278px;
  }
  .selectWrapper .vn-select {
    position: absolute;
    left: 640px;
    right: 40px;
  }
}

@media (min-width: 1440px) {
  .orderDeviceTab .amount {
    margin-right: 30px;
  }
  .caseDeviceTab .amount {
    margin-right: 30px;
  }
}

/* TODO: Move to module - Why don't some css files work???*/

.persona-tile-container {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 30px;
}

.persona-tile {
  padding: 10px;
  background: var(--white, white);
  box-shadow: 0 0 3px 0 var(--box-shadow-v1, rgba(0, 0, 0, 0.15));
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.persona-tile:hover {
  border: 2px solid var(--blue-v1, #0096d6);
}

.theme-blue:hover {
  border: 2px solid var(--blue-v1, #0096d6);
}

.theme-green:hover {
  border: 2px solid var(--green, #4fa33d);
}

.theme-orange:hover {
  border: 2px solid var(--orange-v1, #f28200);
}

.theme-purple:hover {
  border: 2px solid var(--purple, #a33fa3);
}

.persona-tile-link,
.persona-tile-link:hover,
.persona-tile-link:active {
  display: block;
  color: var(--primary-gray, #666666);
  text-decoration: none;
}

.persona-tile-title {
  position: relative;
  width: 100%;
  text-align: center;
  overflow-wrap: break-word;
  margin-top: 20px;
  font-size: 20px;
  user-select: none;
}

/* Fix for broken styles in veneer/file-picker */
.vn-filepicker__file-name {
  box-sizing: content-box;
}

/* Veneer style overrides */

.vn-side-menu__menu>ul {
  height: 100%;
}

.vn-checkbox__span-text {
  position: relative;
  top: 1px;
  vertical-align: top;
}

.vn-panel__title {
  font-size: 18px;
  text-transform: capitalize;
}

.vn-panel__title svg {
  top: 2px;
}

.vn-panel__header {
  padding: 16px 20px;
}

.vn-datepicker__arrow--next,
.vn-datepicker__arrow--prev {
  height: 30px;
  width: 30px;
  padding: 0;
}

.vn-list-item {
  word-break: break-all;
  width: 100%;
}

.vn-list-item__content--middle {
  flex-basis: auto !important;
}

.marginTop10 {
  margin-top: 10px;
}

.headerPopupHover .vn-popover .vn-popover__content--visible .vn-contextual-menu .vn-options-list .vn-option:active {
  background: var(--blue-v2, #0195d6);
}

/* OVERRIDE LIB STYLE - START */

.react-slideshow-container .nav span {
  border: 1px;
  width: 12px !important;
  height: 12px !important;
}

.hardwareDetail .vn-modal__header {
  text-align: center !important;
  margin-bottom: 0px !important;
  padding: 20px 40px 0px 30px !important;
  background: var(--bg-v1, #f9f9f9);
}

.hardwareDetail .vn-modal__header h4 {
  font-size: 1.1rem !important;
}

.hardwareDetail .vn-modal__content {
  padding: 0px;
}

.orderDetailIcon .vn-icon--size-64 {
  height: 40px;
}

.react-slideshow-container .nav,
.react-slideshow-container .nav:hover {
  background: none !important;
}

.vn-tabs--underline-theme .vn-tabs__tab--enabled:focus,
.vn-tabs--underline-theme .vn-tabs__tab--enabled:hover {
  border-bottom: 3px solid var(--theme-blue, #0096d6);
}

.hardwareDetail .vn-tabs__tab {
  text-transform: none !important;
  font-size: 1rem;
  padding: 7px 20px;
}

.hardwareDetail .vn-tabs--underline-theme nav {
  text-align: center;
  border-bottom: 1px solid var(--underline, #e0e0e0);
}

.hardwareDetail .vn-tabs--underline-theme .vn-tabs__tab--active {
  font-weight: bold !important;
}

.hardwareDetail .vn-tabs__content {
  padding: 10px 30px 20px 50px;
}

.hardwareDetail .vn-panel__header {
  padding: 8px 15px 8px 19px !important;
}

.hardwareDetail ul {
  list-style: disc;
  padding-left: 16px;
}

.languagePreference .vn-options-list {
  overflow-y: scroll;
  height: 180px !important;
}

/* OVERRIDE LIB STYLE - END */

.react-slideshow-container+div.indicators>div {
  width: 10px !important;
  height: 10px !important;
}

.required:after {
  content: " *";
  color: var(--red, red);
}

.siteSelection .vn-option,
.selectContract .vn-option,
.selectCountry .vn-option,
.showCbnCrs .vn-option,
.selectCustomer .vn-option {
  height: fit-content !important;
  height: auto !important;
  min-height: 24px !important;
}

.siteSelection .vn-options-list {
  overflow-y: unset !important;
  min-height: 65px !important;
  min-height: 2em !important;
  height: auto !important;
  max-height: 360px !important;
}

.hardwareDetail .vn-panel__content {
  padding: 10px 30px 20px 20px !important;
}

.leavingWarning .vn-labeled-icon__label {
  font-size: x-large;
}

/* OVERRIDE  LOCAL STYLE -START */

.kSGZwm .ckFUEe .koacuh .iDYVoh {
  width: 130px;
}

/* OVERRIDE  LOCAL STYLE -end */

.posRelative {
  position: relative;
  display: flex;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.vn-modal__body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.vn-modal__body {
  -ms-overflow-style: none;
}

.hrWidth {
  width: 100%;
  margin-top: 10px;
}

.mainCart:nth-child(even) {
  background: var(--bg-v4, #f8f8f8);
}

.cartItem {
  min-height: 5em;
}

.vn-notification__badge {
  background: var(--blue-v2, #0195d6);
  right: -3px;
}

.cartInfoIcon {
  width: 25px;
  max-width: 25px;
  float: left;
  margin-top: 9px;
}

.cartInfoIcon.bcIcon {
  position: absolute;
  left: -2px;
}

.displayFlex {
  display: flex;
}

.displayFlex .vn-panel .vn-panel__header {
  width: calc(100% - 14px);
}

.displayFlex .vn-panel:first-child .vn-panel__header {
  padding-left: 30px;
}

.invitationPeriod {
  display: flex;
  flex-direction: row;
}

.invitationPeriod p:nth-child(2) {
  margin-left: 10px;
}

.ribbon {
  font-size: 16px !important;
  margin-top: -22px;
  width: fit-content;
  padding: 8px 15px;
  position: relative;
  background: var(--blue-v3, #0096d5);
  color: var(--white, white);
  text-align: center;
  display: table-cell;
}

.ribbon:after {
  content: "";
  position: absolute;
  display: block;
  bottom: 0em;
  top: 0em;
  border: 0.902em solid var(--blue-v3, #0096d5);
  z-index: -1;
  right: -1em;
  border-left-width: 1.5em;
  border-right-color: transparent;
}

.ribbon .ribbon-content:after {
  right: 0;
  border-width: 1em 1em 0 0;
}

.non-semantic-protector {
  position: relative;
  z-index: 1;
  margin-top: -1.5em;
}

.selectCustomer .vn-select-content--downwards,
.selectContract .vn-select-content--downwards,
.selectCountry .vn-select-content--downwards,
.showCbnCrs .vn-select-content--downwards,
.siteSelection .vn-select-content--downwards {
  max-width: 100% !important;
}

.selectCustomer .vn-select-content,
.selectContract .vn-select-content {
  z-index: 999;
}

button {
  display: contents;
}

.vn-side-menu__logo-image {
  width: 30px !important;
}

.plusBtn.vn-button {
  width: 25%;
  margin-left: 5%;
  margin-right: 0px;
  min-width: 0px;
}

.minusBtn.vn-button {
  width: 25%;
  margin-left: 0px;
  margin-right: 5%;
  min-width: 0px;
}

.siteSelection .vn-option a div p {
  white-space: normal !important;
}

.selectCustomer .vn-option a,
.selectCountry .vn-option a,
.showCbnCrs .vn-option a,
.selectContract .vn-option a {
  white-space: normal !important;
}

.showCbnCrs .vn-textbox:disabled {
  color: var(--primary-gray, #666666);
}
.floatRight {
  float: right;
}

.clearfix {
  clear: both;
}

.totalTitle {
  margin-top: 2px;
}

.totalPrice {
  min-width: 120px;
  text-align: right;
  font-size: 18px;
  margin-left: 20px;
  font-weight: bold;
}

.marginLeft10 {
  margin-left: 10px;
}

.marginTop30 {
  margin-top: 30px;
}

.mainBlock {
  height: 32px;
}

.alternative_cls:nth-child(even) {
  background: var(--bg-v4, #f8f8f8);
}

.dashboardForm {
  margin-top: 24px;
}

.alignRight {
  text-align: right;
}

.alignLeft {
  text-align: left;
}

.pasZero {
  padding: 0px;
}

.advanceFilterModal .vn-modal__content {
  padding: 31px 30px 16px 30px;
  margin: 10px;
}

@media (max-width: 360px) {
  .advanceFilterModal .vn-modal__content {
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
  }

  .advanceFilterModal .vn-modal__content {
    padding: 0px;
  }
}

.advSearchBtnsComp {
  margin-bottom: 15px;
  margin-top: 20px;
}

.mobileSearch {
  padding: 10px 12px 10px 12px
}

.customerListTable .mobileSearch,
.contractTableList .mobileSearch {
  padding: 10px 0px 10px 0px
}

.advanceFilterModal .vn-modal__dialog {
  width: 1100px;
}

/* RESET MODAL POPUP CSS because filter popup is inside advance search popup - START */
.filterPopupForMobile .vn-modal__dialog {
  margin-top: 30px;
  max-height: 100%;
  height: inherit;
  position: relative;
}

.filterPopupForMobile .vn-modal__dialog--sm {
  width: 480px;
}

.filterPopupForMobile .vn-modal__content {
  background-color: var(--white, white);
  box-shadow: 0 5px 15px rgba(102, 102, 102, 0.5);
  margin-bottom: 30px;
  padding: 0px;
  position: relative;
}

/* RESET MODAL POPUP CSS - END */

.vn-button--secondary--hoverable:hover,
.vn-button--secondary:active {
  background-color: transparent;
}

.vn-icon-button:hover,
.vn-icon-button:active,
.vn-icon-button:focus,
.vn-icon-button {
  background-color: transparent;
  border: none;
  color: var(--theme-blue, #0096d6);
}

.headerIcons .vn-icon-button {
  color: var(--gray-v1, #666666);
  height: 32px;
}

.headerIcons .vn-appbar__content {
  padding: 0px !important;
}

.helpIcon {
  position: relative;
  right: 14px;
  margin-top: -3.3px;
}

.helpIconButton {
  position: relative;
  right: 14px;
  margin-top: -3.3px;
  border-radius: 50%;
  height: 32px;
}

.helpIconButton:focus {
  margin-top: -3.3px;
  border-radius: 50%;
  height: 32px;
  right: 14px;
  background: var(--background, #f2f2f2);
}

.helpLink {
  padding: 6px;
  color: var(--title-gray, #666666);
  text-align: left;
}

.helpLink:hover {
  color: var(--white, white);
  cursor: pointer;
  background-color: var(--bg-v7, #0084bc) !important;
  text-decoration: none;
}

.modifySecBtn .vn-button--secondary {
  border: none;
  text-transform: capitalize;
  font-family: "HP Simplified Light", Arial, sans-serif;
  font-size: 14px;
  color: var(--theme-blue, #0096d6);
  text-align: right;
  padding-right: 0px;
  height: 28px;
  background-color: transparent;
}

.modifySecBtn button svg {
  position: absolute;
  display: inline-block;
  top: 6px;
  left: 0px;
}

.paddingLeft30 {
  padding-left: 10px;
}

.colBlock {
  width: 50%;
  display: inline-block;
}

.row.uploadRow {
  background-color: var(--bg-v8, #f3f3f3);
  margin: 0px;
}

.uploadRow .alignRight button svg {
  height: 20px;
  width: 20px;
}

.col.modifySecBtn {
  max-width: fit-content;
  width: fit-content;
  min-width: fit-content;
}

p.normalText {
  font-size: 12px;
}

.doRelative tr {
  position: relative;
}

.doRelative tr:nth-child(even) {
  background-color: var(--bg-v4, #f8f8f8);
}

.customSpinnerParent {
  position: relative;
  top: 4px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: var(--bg-v3, #e6e6e680);
}

.customSpinnerParent.topPosition {
  top: 130px;
}

.customSpinnerParent .vn-spinner {
  position: absolute;
  top: 45%;
  left: 45%;
}

.reuploadWarning {
  font-size: 12px;
  color: var(--red, red);
  margin-top: -15px;
}

.cartTextbox .vn-textbox--expanded {
  text-align: center;
}

.cart .vn-keep-placeholder-on-focus {
  margin: 0 -30%;
}

.cartTextbox {
  width: 40%;
  text-align: center;
}

.paginationBlock {
  height: 70px;
}

.advanceFilterModal {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.marginRight10 {
  margin-right: 10px;
}

.tableTd .vn-radio-button-box span {
  margin-top: -3px;
  padding-left: 25px;
  font-size: 13px;
}

.vn-pagination__navigation-item-content:focus:not(.vn-pagination__navigation-item-content--disabled):not(.vn-pagination__navigation-item-content-page--ellipsis) {
  border: none;
  text-decoration: none;
}

.col.filterCol {
  max-width: 110px;
  padding: 0px;
  text-align: right;
}

.col.searchCol {
  padding: 0px;
}

.row.mobileSearch {
  margin: 0px;
}

.skusection .vn-select__title--disabled {
  border: 1px solid var(--gray-v1, #666666);
  color: var(--gray-v1, #666666);

}

.skusection .vn-select__title-text--disabled {
  color: var(--gray-v2, #999999);
}

.skusection .vn-icon--disabled {
  color: var(--gray-v1, #666666);
}

.skusection .vn-option {
  display: flex;
}

.skusection .vn-select__title-text--placeholder {
  display: flex;
}

.skusection .vn-select__title-text {
  display: flex;
}

.noResultText {
  text-align: center;
  width: 100%;
}

.leftButton {
  width: 120px;
}

.rightButton {
  width: 120px;
}

.marginLeft15 {
  margin-left: 15px;
}

.wrapper .vn-search {
  margin-left: 15px;
}

.rightBlock {
  width: 50%;
}

.overrideListHeader {
  margin: 0px !important;
}

.rightButton a {
  width: 100%;
}

.wrapper button .filterIcon svg.vn-icon {
  position: absolute;
  top: 3px;
  color: var(--gray-v3, #757574);
  height: 22px;
  width: 22px;
}

.wrapper .vn-search__input {
  padding-right: 60px;
}

.filterWrapper .sectionWrapper {
  overflow-y: unset;
}

.custom-icon-contract {
  width: 60px;
  height: 60px;
  color: var(--orange, #f28200);
}

.vn-side-menu__item .vn-side-menu__item-link.vn-side-menu__item-link--hoverable {
  cursor: pointer;
}

.pageSection .vn-button--primary:active {
  background-color: var(--border, #e6e6e6);
  border: 1px solid var(--border, #e6e6e6);
}

.pageSection .vn-button--primary:focus {
  background-color: var(--border, #e6e6e6);
  border: 1px solid var(--border, #e6e6e6);
}

.hpContacts .vn-panel__header {
  background-color: var(--bg-v7, #0084bc);
  padding: 0px 0px 0px 10px;
  height: 40px;
}

.hpContactsNew .vn-panel__content {
  padding: 0px !important;
}

.hpContacts .vn-panel__title {
  color: var(--white, white);
  font-size: 14px;
  font-weight: bold;
}

.hpContacts .vn-panel__content {
  padding: 7px 11px;
}

.hpContactsOperational .vn-panel__header {
  background-color: var(--bg-v9, #def1f5);
  padding: 0px 0px 0px 10px;
  height: 40px;
}

.hpContactsOperational .vn-panel__title {
  color: var(--gray-v1, #666666);
  font-size: 14px;
  font-weight: bold;
}

.hpContactsOperational .vn-panel__content {
  padding: 7px 11px;
}

.hpContactsOperational .vn-panel__toggle-icon {
  color: var(--gray-v1, #666666) !important;
  margin-right: 25px;
  top: -2px
}

.contractInformation .vn-panel__content {
  padding: 7px 11px;
}

.hpContacts .vn-panel__toggle-icon {
  color: var(--white, white) !important;
  margin-right: 25px;
  top: -2px
}

.contractInformation .vn-icon-button {
  color: var(--white, white);
  height: 31px;
  width: 48px;
}

.buttonWrapper {
  margin-top: 50px;
}

.buttonWrapper .vn-button--secondary {
  background-color: var(--bg-v7, #0084bc);
  color: var(--white, white);
  top: 10px;
}

.buttonWrapper .vn-button--secondary:hover {
  color: var(--white, white);
}

.orderListTable tbody td,
.contractTable tbody td,
.documentTable tbody td {
  font-size: 15px;
}

.orderListTable thead td:first-child,
.customerListTable thead td:first-child,
.contractTable thead td:first-child,
.customerListTable thead td:first-child,
.documentTable thead td:first-child,
.contractTableList thead td:first-child {
  padding-left: 10px;
}

.custom-icon-contract {
  width: 60px;
  height: 60px;
  color: var(--orange, #f28200);
}

.contractTable nav,
.documentTable nav {
  position: relative;
  z-index: 0;
  top: 0px;
  width: -webkit-fill-available;
  background: var(--white, white);
  border-top: 0px solid var(--background);
  margin-left: 0px;
}

.contractTable .vn-tabs__tab,
.documentTable .vn-tabs__tab {
  padding: 7px 20px;
}

.contractTable .tableRow:nth-child(even) {
  background-color: var(--bg-v4, #f8f8f8);
}

.contractTable .vn-panel__content {
  padding: 8px 12px;
}

.contractTable {
  margin-top: 20px;
}

.mobileSearch .filterCol svg.vn-icon {
  position: absolute;
  top: 3px;
  color: var(--gray-v3, #e6e6e680);
  height: 22px;
  width: 22px;
  right: -3px;
}

.mobileSearch .selectAllCol {
  flex-grow: 0;
  width: 50px;
  padding-left: 0;
  padding-top: 6px;
}

.mobileSearch .selectAllCol > div {
  display: inline;
}

/* Sideemnu padding left for top title */

.placeOrderBundle .vn-button,
.placeOrderContract .vn-button {
  text-transform: inherit !important;
}

.placeOrderBundle .vn-icon--size-32,
.addRowContract .vn-icon--size-32,
.placeOrderContract .vn-icon--size-32 {
  height: 26px !important;
  width: 24px !important;
}

.addRowContract .vn-button {
  height: 30px !important;
}

.tableData button {
  display: ruby-base;
  display: contents;
}
.tableData .vn-modal__close {
  display: block;
}

.buttonLink {
  padding: 5px;
  color: var(--white, white);
  text-align: left;
}

.buttonLink:hover {
  color: var(--white, white);
  cursor: pointer;
  background-color: none !important;
  text-decoration: none;
}

.attachedFiles .vn-panel__header {
  background-color: var(--bg-v7, #0084bc)
}

.attachedFiles .vn-panel__title {
  color: var(--white, white);
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}

.attachedFiles .vn-panel__content {
  padding: 17px 11px;
}

.orderDeviceTab .vn-tabs__content {
  border-top: 20px solid var(--background, #f2f2f2);
  padding: 0px !important;
}

.orderDeviceTab .vn-tooltip__content {
  font-weight: normal;
}

.newCustomer .vn-tabs__tab {
  width: 50%;
}
.newCustomer .vn-tabs__content {
  padding: 0px;
  overflow-y: auto;
}

.caseDeviceTab .vn-tabs__content {
  border-top: 20px solid var(--background, #f2f2f2);
  padding: 0px !important;
  background-color: var(--background, #f2f2f2);
}

.customerTab > .vn-tabs__content {
  border-top: 20px solid var(--background, #f2f2f2);
  padding: 0px !important;
  background-color: var(--background, #f2f2f2);
  margin-bottom: 20px;
}

.caseDeviceTab .vn-tooltip__content {
  font-weight: normal;
}

.contractMoreIcon .vn-contextual-menu {
  text-align: left;
}

.roleSelector {
  margin: 0px;
}

.roleSelector label {
  margin-bottom: 5px;
}

.preference {
  padding: none;
}

.approveCancellation {
  text-align: left;
}

.col.viewStatusButton {
  padding: 0px;
}

.col.estimatedDeliveryBlock {
  margin-top: 30px;
  padding-left: 0px;
  padding-right: 10px;
}

.statusActionBlock .rejectedBlock {
  width: 100%;
}

.caseRequestDocument  {
  margin-top: -20px;
}

p.awaitingAppText {
  font-size: 12px;
}

.checkBorderActiveColor {
  border-color: var(--theme-blue, #0096d6);
}

.vn-panel__content .documentRow div:first-child {
  border-top-width: 8px;
}

.checkBorderInActiveColor {
  border-color: var(--gray-v1, #666666);
}

.row.orderTablePriceRow {
  background: var(--bg-v4, #f8f8f8);
  padding: 8px;
  margin: 0px;
}

.col.orderTablePriceMobileview {
  padding: 0px;
  min-width: 138px;
}

.col.orderTablePriceRowRightCol {
  min-width: 200px;
  padding-left: 100px;
  padding: 0px;
}

.col.orderTablePriceRowRightCol.colWebWidth {
  max-width: 200px;
}

.col.orderTablePriceRowRightCol.colMobileWidth {
  max-width: 100%;
  min-width: 0px;
}

.customAstrik {
  color: var(--astrik, #cf4030);
  margin-left: 5px;
}

.roleIcon {
 width: 100%;
}

.noDeviceData {
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
}

.caseAlertModal .vn-modal__close {
  display: none;
}

.successMessage {
  color: var(--orange, #f28200);
  font-weight: bold;
}

.errorMessage {
  color: var(--red, red);
}

.vn-modal-html-body {
  overflow: auto;
  scrollbar-width: thin;
}

.disableBg {
  background: #f7f7f7 !important;
  border-color: #cccccc !important;
}

.itemSelected {
  box-shadow: -4px 0px 6px 0px rgb(0 0 0 / 20%);
  background-color: white;
  z-index: 1;
}

.itemSelected {
  box-shadow: -4px 0px 6px 0px rgb(0 0 0 / 20%);
  background-color: white;
  z-index: 1;
}

.toggleEditPermission a {
  margin-top: 10px;
}

.closeButtonIcon {
  color: unset;
}

.vn-form-field__error-message {
  white-space: pre-line;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

body > span#kampyleButtonContainer {
  z-index: 10000;
  position: fixed;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  pointer-events: none;
  height: 41px;
}

body > span#kampyleButtonContainer > button {
  pointer-events: all;
  padding: 0;
  left: 50% !important;
  margin-left: -45px;
  bottom: 0;
  border: none;
  position: relative !important;
  cursor: pointer;
  line-height: 1px;
  display: block;
  top: auto !important;
  height: 41px;
}
