.dui-welcome {
  background-color: var(--white, white);
  min-height: 100vh;
  position: relative;
}

.learn-more,
.key-areas-title a:hover {
  font-size: 30px;
  color: var(--white, white);
  text-decoration: underline;
}

.dui-welcome h1,
.dui-welcome h2,
.dui-welcome h3,
.dui-welcome h4,
.dui-welcome h5,
.dui-welcome h6,
.dui-welcome .h1,
.dui-welcome .h2,
.dui-welcome .h3,
.dui-welcome .h4,
.dui-welcome .h5,
.dui-welcome .h6,
.dui-welcome p,
.dui-welcome div {
  font-family: "HP Simplified Light";
}

.dui-welcome h3 {
  margin-bottom: 20px;
}

.dui-welcome hr {
  border: 0px;
  height: 1px;
  background-color: var(--bg-v10, #cccccc);
}

.dui-welcome .dui-welcome-header .content-wrapper,
.dui-welcome .dui-welcome-footer .content-wrapper {
  display: block;
  margin: auto;
  padding: 15px 0px 10px;
  width: 96%;
}

.dui-welcome .dui-welcome-header .content-wrapper .right-section,
.dui-welcome .dui-welcome-footer .content-wrapper .right-section {
  float: right;
}

.dui-welcome .dui-welcome-header .content-wrapper .welcome-signin-button,
.dui-welcome .dui-welcome-footer .content-wrapper .welcome-signin-button {
  position: relative;
  top: 15px;
  padding: 0 30px;
}

.dui-welcome .dui-welcome-header .content-wrapper .welcome-signin-button a,
.dui-welcome .dui-welcome-footer .content-wrapper .welcome-signin-button a,
.dui-welcome .dui-welcome-header .content-wrapper .welcome-signin-button a:hover,
.dui-welcome .dui-welcome-footer .content-wrapper .welcome-signin-button a:hover {
  text-decoration: none;
}

.col.leftCol {
  max-width: 70px;
  padding-left: 5px;
  padding-right: 0px;
}

.col.rightCol {
  text-align: right;
  padding-left: 0px;
  padding-right: 5px;
  margin-bottom: 30px;
}

.rightCol .secondBtn {
  margin-left: 15px;
}

.dui-welcome .dui-welcome-footer .content-wrapper {
  font-size: 12px;
  line-height: 18px;
  padding: 0px 0px 10px;
  width: 95%;
}

.dui-welcome .dui-welcome-footer .vn-select .vn-labeled-flag,
.dui-welcome .dui-welcome-footer .vn-select .vn-labeled-flag__label {
  font-size: 12px;
  line-height: 18px;
}

.dui-welcome .dui-welcome-footer .right-section {
  float: right;
}

.dui-welcome .dui-welcome-footer .right-section .lnk-privacy {
  display: inline-block;
  position: relative;
  top: 9px;
}

.dui-welcome .dui-welcome-footer .right-section .change-locale {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
}

.dui-welcome .dui-welcome-footer .right-section .change-locale .vn-select {
  min-width: 150px;
}

.dui-welcome .dui-welcome-footer .right-section .change-locale .vn-select div,
.dui-welcome .dui-welcome-footer .right-section .change-locale .vn-options-list>div {
  box-sizing: content-box;
}

.dui-welcome .dui-welcome-footer .right-section .change-locale .vn-select__title-text[aria-hidden="true"] {
  display: none;
}

.dui-welcome .dui-welcome-footer .right-section .welcome-signin-button {
  position: relative;
  top: 15px;
  padding: 0 30px;
}

.dui-welcome .dui-welcome-footer .left-section {
  float: left;
  position: relative;
  top: 9px;
}

.dui-welcome .dui-banner-container {
  position: relative;
}

.dui-welcome .dui-banner-row {
  height: 44vw;
  background-image: url("images/top-image.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.dui-welcome .banner-text {
  margin-top: 20px;
}

.dui-welcome .banner-text .hp-daas-title {
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  text-align: center;
}

.dui-welcome .banner-text .smart-simplified-co {
  font-size: 28px;
  font-weight: lighter;
  line-height: 39px;
  text-align: center;
  margin: 15px auto;
  padding: 0px 120px;
}

.dui-welcome .banner-text .smart-simplified-co h3 {
  font-size: 1.35rem;
}

.hp-daas-desc h6 {
  font-size: 0.9rem;
}

.dui-welcome .banner-text .smart-simplified-co,
.dui-welcome .banner-text .hp-daas-desc {
  padding-top: 10px;
  margin: 10px auto;
}

.dui-welcome .banner-text .hp-daas-desc {
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  text-align: center;
  margin-top: 40px;
}

.dui-welcome .dui-whats-new-container .whats-new-left-panel {
  margin: 0;
  flex-direction: column;
}

.dui-welcome .dui-whats-new-container .whats-new-left-panel .key-areas-title {
  margin: 117px 0px 40px 0px;
}

.dui-welcome .dui-whats-new-container .whats-new-left-panel .key-areas-title h5 {
  color: var(--white, white);
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  max-width: 380px;
  max-width: 380px;
  margin: auto;
  margin-left: 31px;
}

.dui-welcome .dui-whats-new-container .whats-new-right-panel .whats-new-text {
  padding-bottom: 10px;
  margin-top: 20px;
}

.dui-welcome .dui-whats-new-container .whats-new-right-panel .section-1 {
  margin-top: 15px;
  color: var(--white, white);
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
}

.dui-welcome .dui-whats-new-container .whats-new-right-panel .whats-new-title,
.dui-welcome .dui-whats-new-container .whats-new-right-panel .owner-name {
  margin-bottom: 20px;
}

.dui-welcome .dui-whats-new-container .whats-new-right-panel .whats-new-title h3,
.dui-welcome .dui-whats-new-container .whats-new-right-panel .owner-name h3 {
  color: var(--white, white);
  font-size: 30px;
  font-weight: 300;
  line-height: 43px;
}

.dui-welcome .dui-whats-new-container .whats-new-right-panel .description-2,
.dui-welcome .dui-whats-new-container .whats-new-right-panel .description-3,
.dui-welcome .dui-whats-new-container .whats-new-right-panel .description-4 {
  margin-top: 20px;
  color: var(--gray-v1, #666666);
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
}

.dui-welcome .dui-whats-new-container .whats-new-right-panel .description-3,
.dui-welcome .dui-whats-new-container .whats-new-right-panel .description-4 {
  margin-top: 20px;
}

.dui-welcome .dui-whats-new-container .whats-new-right-panel .description-2 {
  margin-top: 30px;
}

.dui-welcome .key-areas-box {
  display: flex;
  justify-content: space-evenly;
  padding: 0;
  margin: 0 0 0 -5px;
}

.dui-welcome .key-areas-box div {
  padding: 0;
}

.dui-welcome .key-areas-box div .box {
  width: 160px;
  height: 162px;
  padding: 0;
  margin: auto;
  background-color: var(--white, white);
  text-align: center;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
}

.dui-welcome .key-areas-box div .box .vn-icon {
  margin-top: 20px;
}

.dui-welcome .key-areas-box div .box p {
  font-size: 12px;
  padding: 5px 12px;
  font-weight: 300;
  line-height: 16px;
  color: var(--gray-v1, #666666);
}

.dui-welcome .world-devices {
  margin-top: 30px;
}

.dui-welcome .world-devices hr {
  color: var(--bg-v10, #cccccc);
}

.dui-welcome .world-devices .map {
  padding: 0 20px;
}

.dui-welcome .world-devices .map .world-map-background {
  background-image: url("images/map-svg.svg");
  height: 280px;
  max-height: 50vw;
  background-size: contain;
  background-repeat: no-repeat;
}

.dui-welcome .world-devices .release-container {
  margin: 50px 0 0 0;
}

.dui-welcome .world-devices .devices-release .release-text {
  margin-top: 40px;
  text-align: center;
}

.dui-welcome .tnc-note {
  background-color: var(--background, #f2f2f2);
}

.dui-welcome .tnc-note p {
  color: var(--gray-v1, #666666);
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  padding: 15px 0px 0px 0px;
}

.dui-welcome .welcome-footer.footer-hp {
  height: 41px;
  padding: 0;
  background-color: var(--white, white);
}

.dui-welcome .welcome-footer.footer-hp #footer-copyright {
  float: left;
  line-height: 40px;
  padding: 0 40px;
}

.dui-welcome .welcome-footer.footer-hp #footer-terms-n-conditions {
  float: right;
  line-height: 40px;
  padding: 0 30px;
}

.dui-welcome .welcome-footer.footer-hp .footer-flag {
  margin-left: 20px;
  vertical-align: middle;
  transform: scale(0.75);
  cursor: pointer;
}

.dui-welcome .welcome-footer.footer-hp .flag {
  display: inline-block;
  width: 24px;
  height: 24px;
  padding-right: 10px;
}

.dui-welcome .footer-wrapper {
  background: var(--white, white);
  bottom: 0px;
  position: absolute;
  width: 100%;
}

.dui-welcome .tnc-background {
  background: var(--background, #f2f2f2);
}

.dui-welcome .bg_container-full-width {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 10000px;
  left: -5000px;
  background: var(--background, #f2f2f2);
}

.dui-welcome #device_count {
  margin-top: 20px;
  height: 100px;
  min-width: 540px;
  max-width: 100%;
  color: var(--white, white);
  font-size: 60px;
  font-weight: 300;
  line-height: 72px;
  text-align: center;
  cursor: default;
  pointer-events: none;
  font-family: "HP Simplified Light";
}

.dui-welcome .dui-whats-new-background {
  background-image: url("images/blue-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70px;
}


.dui-welcome.locale_zh_CN .dui-whats-new-background {
  background-size: 100% 230px;
}

.dui-welcome.locale_fi #device_count {
  font-size: 40px;
}

.dui-welcome.locale_es #device_count {
  font-size: 40px;
}

.dui-welcome.locale_nl #device_count,
.dui-welcome.locale_pt_PT #device_count {
  font-size: 60px;
}

.dui-welcome.locale_fr #device_count,
.dui-welcome.locale_ja #device_count,
.dui-welcome.locale_pt_BR #device_count {
  font-size: 55px;
}

@media (max-width: 1199.98px) {
  .dui-welcome .dui-main-container .dui-whats-new-container .whats-new-right-panel .description-2 {
    margin-top: 30px;
  }

  .dui-welcome .dui-main-container .world-devices {
    margin-top: 30px;
  }

  .dui-welcome .key-areas-box div .box {
    width: 130px;
    height: 180px;
  }

  .dui-welcome #device_count {
    min-width: 450px;
  }

  .dui-welcome.locale_fi #device_count {
    font-size: 40px;
  }

  .dui-welcome.locale_es #device_count {
    font-size: 25px;
  }

  .dui-welcome.locale_nl #device_count,
  .dui-welcome.locale_pt_PT #device_count {
    font-size: 38px;
  }

  .dui-welcome.locale_fr #device_count,
  .dui-welcome.locale_ja #device_count,
  .dui-welcome.locale_pt_BR #device_count {
    font-size: 34px;
  }
}

.dui-welcome .banner-text {
  color: var(--white, white);
  z-index: 2;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .dui-welcome .dui-main-container .dui-whats-new-container .whats-new-left-panel .key-areas-title h5 {
    margin: auto;
  }

  .dui-welcome .dui-main-container .world-devices {
    margin-top: 30px;
  }

  .dui-welcome .dui-main-container .world-devices .devices-release .release-text {
    margin-top: 20px;
  }

  .dui-welcome .dui-main-container .world-devices .devices-release .release-text h3 {
    font-size: 24px;
  }

  .dui-welcome .dui-main-container .world-devices .devices-release div {
    text-align: center;
  }

  .dui-welcome .key-areas-box div .box {
    margin-bottom: 20px;
    height: 160px;
    width: 160px;
  }

  .dui-welcome #device_count {
    min-width: 330px;
    height: 80px;
    font-size: 42px;
  }
}

@media (max-width: 767.98px) {

  .dui-welcome .dui-welcome-footer .left-section,
  .dui-welcome .dui-welcome-footer .right-section {
    width: 100%;
    padding: 0px 15px;
    text-align: left;
  }

  .dui-welcome .dui-welcome-footer .left-section {
    margin-bottom: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .dui-welcome .dui-main-container .world-devices {
    margin-top: 30px;
  }

  .dui-welcome .dui-main-container .world-devices .devices-release .release-text h3 {
    font-size: 24px;
  }

  .dui-welcome .dui-main-container .world-devices .devices-release div {
    text-align: center;
  }

  .dui-welcome .dui-main-container .dui-whats-new-container .whats-new-left-panel .key-areas-title h3 {
    margin: auto;
  }

  .dui-welcome .key-areas-box div .box {
    margin-bottom: 20px;
    height: 160px;
    width: 160px;
  }

  .dui-welcome #device_count {
    font-size: 42px;
  }
}

@media (max-width: 575.98px) {
  .dui-welcome .dui-main-container .dui-whats-new-container .whats-new-left-panel {
    display: none;
  }

  .dui-welcome .dui-main-container .dui-whats-new-container .whats-new-right-panel div {
    padding-left: 15px;
  }

  .dui-welcome .dui-main-container .dui-whats-new-container .whats-new-right-panel .section-1 {
    padding: 20px 15px 20px;
    margin-top: 0px;
    margin: 0px -15px;
    background: var(--blue-v1, #0096d6);
  }

  .dui-welcome .dui-main-container .world-devices .devices-release .release-text h3 {
    font-size: 20px;
  }

  .dui-welcome .dui-main-container .world-devices .devices-release div {
    text-align: center;
  }

  .dui-welcome .tnc-note p {
    padding: 15px 0px 0px 0px;
    margin: 0px;
  }

  .dui-welcome #device_count {
    font-size: 42px;
    width: 100%;
    min-width: 50px;
  }

  .dui-welcome.locale_fi #device_count {
    font-size: 37px;
  }

  .dui-welcome.locale_es #device_count {
    font-size: 17px;
  }

  .dui-welcome.locale_nl #device_count,
  .dui-welcome.locale_pt_PT #device_count {
    font-size: 32px;
  }

  .dui-welcome.locale_fr #device_count,
  .dui-welcome.locale_ja #device_count,
  .dui-welcome.locale_pt_BR #device_count {
    font-size: 27px;
  }
}

.dui-welocome-text-white {
  font-weight: bold;
  font-size: 2.15rem;
}

.hp-daas-title {
  position: absolute;
  top: 80px;
  text-align: center;
  width: 100%;
  color: var(--white, white);
}

.disContainer {
  position: absolute;
  top: 120px;
  max-width: 100%;
}

.key-areas-title {
  padding-top: 10px;
}

.termsContainer {
  max-width: 98%;
  width: 98%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 15px;
}

@media (max-width: 510px) {
  .dui-welcome .dui-welcome-header .content-wrapper .welcome-signin-button {
    padding: 0 12px;
    top: 28px;
  }

  .rightCol .secondBtn {
    margin-left: 10px;
  }
}

@media (max-width: 575px) {
  .disContainer {
    position: relative;
    top: 0px;
  }

  .dui-welcome .banner-text {
    color: var(--gray-v1, #666666);
  }

  .dui-welcome .banner-text .smart-simplified-co {
    padding: 0px 10px;
  }

  .dui-welcome .banner-text .hp-daas-desc {
    margin-top: 26px;
    margin-bottom: 20px;
    width: 85%;
  }

  .dui-welcome .tnc-background {
    padding: 0px;
  }

  .hp-daas-title {
    top: 18vw;
  }
}

@media (min-width: 576px) {
  .disContainer {
    position: relative;
    top: 0px;
  }

  .dui-welcome .banner-text {
    color: var(--gray-v1, #666666);
  }

  .dui-welcome .banner-text .hp-daas-desc {
    margin-top: 26px;
    margin-bottom: 20px;
    width: 85%;
  }

  .dui-welcome .tnc-background {
    padding: 0px;
  }

  .hp-daas-title {
    top: 18vw;
  }
  .hp-daas-desc {
    max-width: 570px;
  }

  .dui-welcome .banner-text .smart-simplified-co {
    font-weight: lighter;
    line-height: 39px;
    text-align: center;
    font-size: 1.35rem;
    padding-top: 10px;
    margin: 10px auto;
  }

  .dui-welcome .dui-banner-row {
    height: 44vw;
  }
}

@media (min-width: 668px) {
  .dui-welcome .banner-text .smart-simplified-co {
    padding: 0px 70px;
  }
}

@media (min-width: 768px) {
  .disContainer {
    position: absolute;
    top: 120px;
    max-width: 100%;
  }

  .dui-welcome .banner-text {
    color: var(--white, white);
    z-index: 2;
  }

  .dui-welcome .banner-text .hp-daas-desc {
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    text-align: center;
    margin-top: 40px;
    padding-top: 10px;
  }

  .dui-welcome .tnc-background {
    background: var(--background, #f2f2f2);
  }

  .hp-daas-title {
    position: absolute;
    top: 80px;
    text-align: center;
    width: 100%;
    color: var(--white, white);
    font-size: 20px;
    font-weight: 300;
    line-height: 26px;
  }

  .hp-daas-desc {
    font-weight: 300;
    line-height: 19px;
    text-align: center;
    padding-top: 10px;
    margin: 10px auto;
    font-size: 0.9rem;
  }

  .dui-welcome .banner-text .smart-simplified-co {
    padding: 0px 10px;
  }

  .dui-welcome .banner-text .smart-simplified-co {
    padding: 0px 90px;
  }

  .dui-welcome .dui-banner-row {
    height: 400px;
  }
}

@media (min-width: 892px) {
  .dui-welcome .banner-text .smart-simplified-co {
    padding: 0px 120px;
  }

  .dui-welcome .dui-banner-row {
    height: 450px;
  }
}

@media (min-width: 992px) {
  .hp-daas-desc {
    max-width: 670px;
  }

  .dui-welcome .banner-text .smart-simplified-co {
    padding: 0px 120px;
  }
}

@media (min-width: 1100px) {
  .hp-daas-desc {
    max-width: 800px;
  }

  .dui-welcome .banner-text .smart-simplified-co {
    padding: 0px 120px;
  }
}

@media (min-width: 1200px) {
  .dui-welcome .banner-text .smart-simplified-co {
    padding: 0px 120px;
  }
  .dui-welcome .dui-banner-row {
    height: 600px;
  }
}

@media (min-width: 1300px) {
  .dui-welcome .banner-text .smart-simplified-co {
    padding: 0px 120px;
  }
}

@media (min-width: 1400px) {
  .dui-welcome .banner-text .smart-simplified-co {
    padding: 0px 120px;
  }
}

@media (min-width: 1450px) {
  .dui-welcome .banner-text .smart-simplified-co {
    padding: 0px 120px;
  }
}

@media (min-width: 1500px) {
  .dui-welcome .banner-text .smart-simplified-co {
    padding: 0px 120px;
  }
}

.mobileSignIn {
  position: absolute;
  font-size: 15px;
}

.row.headerRow {
  padding: 0px;
  margin: 0px;
}

.mobileTerms {
  padding: 10px;
}

.mobileTerms p {
  font-size: 12px;
}

.mobileTerms .terms2 {
  margin-top: 15px;
}

.dui-welcome-header .row {
  margin: 0px;
}